<template>
  <div class="match-the-cards-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="match-the-cards-game-content">


      <div class="title-area" v-if="titleInfo" :class="{'small':titleInfo.size == 'small', 'zindex-0': titleInfo.zindex == 0, 'padding-20':titleInfo.padding == 20, 'scale-8':titleInfo.scale == 0.8 }">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="objectsCont" :class="'type-' + lessonNum" >
        <div class="objects" :class="'lesson-' + lessonNum" v-for="(item, index) in imgList" :key="index">
          <div
            :class="{
              isFinishMatchOne: item.type === 1,
              isFinishMatchTwo: item.type === 2 || item.type === 4,
              isFinishMatchThree: item.type === 3,
              cardIsActive: item.cardActive == true,
              style1: index % 2 == 0,
              style2: index % 2 != 0,
            }"
            class="card"
            @click.stop="foundAnswer(index, item)"
          >
            <img class="objectImage" :src="item.image" />
          </div>
        </div>
      </div>
      <Star
        :totalStarNumber="imgList.length / 2"
        :currentIndex="answersfound"
      />
      <div v-show="isMatchDisplay === true" class="matchDisplay">
        <div class="objectDisplaycont">
          <div class="objects">
            <div class="card style1">
              <img class="objectImage" :src="objectDisplayFirst" />
            </div>

            <div class="card style2">
              <img class="objectImage" :src="objectDisplaySecond" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  props: {
    imgList: {
      type: Array,
      require: true,
      defalut: () => [],
    },
    lessonNum: {
      type: Number,
      require: true,
    },
    titleInfo:{
      type: Object,
      require: false,
      defalut: () => {},
    }
  },
  data() {
    return {
      answersfound: 0,
      question: 0,
      isMatchDisplay: false,
      firstClick: 0,
      secondClick: 0,
      firstClickIndex: 0,
      isLastStep: false,
      objectDisplayFirst: null,
      objectDisplaySecond: null,
      clickSocketInfo: {},

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  components: {
    Star,
    PageButton,
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20041,
          data: { value },
          text: "MatchTheCardsGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("ClickTheCards", ({ index, item }) => {
      this.foundAnswer(index, item, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("ClickTheCards");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    foundAnswer(index, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          index,
          item,
          value: Math.random(),
        };
      }
      if (this.firstClick == 0) {
        this.firstClick = item.id;
        this.firstClickIndex = index;
        console.log(this.firstClickIndex);
        this.imgList[index].cardActive = true;
        return;
      } else {
        this.secondClick = item.id;
      }
      if (
        this.secondClick == this.firstClick &&
        this.firstClickIndex !== index
      ) {
        //correct match
        this.objectDisplayFirst = this.imgList[this.firstClickIndex].image;
        this.objectDisplaySecond = this.imgList[index].image;
        this.isMatchDisplay = true;
        this.answersfound++;
        this.$emit(
          "changeCardBG",
          index,
          this.firstClickIndex,
          this.answersfound
        );
        playCorrectSound(true, false);

        setTimeout(() => {
          this.isMatchDisplay = false;
          this.firstClick = 0;
          this.secondClick = 0;
          this.firstClickIndex = 0;

          if (this.question < this.imgList.length / 2 - 1) {
            this.question++;
          } else {
            this.isLastStep = true;
            playCorrectSound();
            startConfetti();
          }
        }, 1000);
      } else {
        playCorrectSound(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.match-the-cards-game-container {
  background: #fff;
  border-radius: 48px;
  position: relative;
  .match-the-cards-game-content {
    background: #f9f6e6;
    border-radius: 48px;
    position: relative;
    width: -moz-fit-content;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 7%;
      width: 25%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: #fff;

      &.small {
        width:15%;
      }
      &.zindex-0 {
        z-index: 0;
      }

      &.padding-20 {
        width: auto;
        padding-left: 20px;
      }
      &.scale-8 {
        transform: scale(0.8);
        transform-origin: left;
      }

      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .objectsCont {
      height: 80%;
      width: 80%;
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      align-content: space-evenly;
       
      .objects {
        width: 30%;
        height: 45%;
     
        .card {
          background-color: #ffffff;
          box-shadow: 0 10px 0 0 #cfc8b0;
          border-radius: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 80%;
          width: 100%;
          .objectImage {
            width: 100%;
            max-height: 100%;
          }
        }

        .cardIsActive {
          border: 10px solid #cd4c3f !important;
          box-shadow: 0 10px 0 0 #cd4c3f;

          border-radius: 40px;
          box-sizing: border-box;
        }

        .style1 {
          transform: rotate(7deg);
        }
        .style2 {
          transform: rotate(-3deg);
        }
        .isFinishMatchOne {
          border-bottom: 10px solid #cd4c3f !important;
          box-shadow: none;
        }
        .isFinishMatchTwo {
          border-bottom: 10px solid #224e96 !important;
          box-shadow: none;
        }
        .isFinishMatchThree {
          border-bottom: 10px solid #f4d737 !important;
          box-shadow: none;
        }
      }
      .lesson-27 {
      width: 34%;
      }
    }
    .type-45 {
      align-content: flex-end;
      .lesson-45 {
        width:21%;
        height:40%;
      }
    }

    .matchDisplay {
      width: 100%;
      height: 100%;
      position: absolute;
      .objectDisplaycont {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba(159, 150, 150, 0.4);
        border-radius: 48px;
        top: 0;
        display: flex;
        flex-direction: row;

        .objects {
          position: relative;
          width: 55%;
          display: flex;
          flex-direction: row;
          align-self: center;
          margin-left: 20%;
          // background-color: black;

          .card {
            height: 90%;
            width: 80%;
            margin-left: 10%;
            background-color: #ffffff;
            border-top: 5px solid #cd4c3f;
            border-left: 5px solid #cd4c3f;
            border-right: 5px solid #cd4c3f;
            border-bottom: 10px solid #cd4c3f;
            border-radius: 20px;
            display: flex;
            align-items: center;
            .objectImage {
              width: 100%;
              max-height: 100%;
            }
          }

          .style1 {
            transform: rotate(7deg);
            //  border-right: 3px solid #CFC8B0;
          }
          .style2 {
            transform: rotate(-7deg);
          }
        }

        .displayObjects {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
        }
      }
    }
  }
}

.lesson45-container .title-area {
  padding: 0 20px;
}
</style>
